import { FC } from 'react'
import NextLink from 'next/link'
import { Box, Center, Text, Link as ChakraLink } from '@chakra-ui/react'

import NotFound404Logo from 'ui/assets/NotFound404Logo'
import ServerError505Logo from 'ui/assets/ServerError505Logo'

export interface ErrorPageProps {
  errorCode: string
}

export const ErrorPage: FC<ErrorPageProps> = ({ errorCode }) => {
  const errorLogos = {
    404: NotFound404Logo,
    500: ServerError505Logo,
  }

  const ErrorLogo = errorLogos[errorCode]

  return (
    <Center
      flexDirection="column"
      height={{ base: '65vh', md: '75vh' }}
      width="100%"
      marginX="auto"
      maxWidth="750px"
      textAlign="center"
    >
      <Box width="95%">
        <ErrorLogo />
        <Text
          color="white"
          fontWeight="bold"
          fontSize="24px"
          fontFamily="codenext"
          marginTop={6}
        >
          {'Something went wrong...'}
        </Text>

        <Box marginTop="40px">
          <NextLink href="/games" passHref>
            <ChakraLink
              borderRadius="99px"
              backgroundColor="chonky.500"
              color="white"
              display="block"
              fontWeight="bold"
              fontFamily="codenext"
              marginX="auto"
              paddingY="12px"
              textDecoration="none"
              width="240px"
              _hover={{
                backgroundColor: 'chonky.700',
                textDecoration: 'none',
              }}
            >
              {'Back to Home'}
            </ChakraLink>
          </NextLink>
        </Box>
      </Box>
    </Center>
  )
}
